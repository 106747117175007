<template>
  <div class="step-bar">
    <div
      class="steps"
      :class="[
        step === activeStep && 'step-active',
        step < activeStep && 'step-prev',
      ]"
      v-for="(step, key) in steps"
      :key="key"
    >
      <div class="step-box">
        <div class="step-number" :class="[size && `step-${size}`]">
          {{ step }}
        </div>
        <span>{{ stepNames.length === 0 ? "Hazırlık" : stepNames[key] }}</span>
      </div>
      <span
        class="divider"
        :class="[
          step < activeStep && 'active',
          step === steps && !lastActive && 'none',
          lastActive && 'arrow',
        ]"
      ></span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    steps: {
      type: Number,
      required: true,
    },
    activeStep: {
      type: Number,
      required: true,
    },
    lastActive: {
      type: Boolean,
      default: false,
    },
    stepNames: {
      type: Array,
      default() {
        return [];
      },
    },
    size: {
      type: String,
      default: "md",
    },
  },
};
</script>
<style lang="scss" scoped>
.step-bar {
  display: flex;
  width: 100%;
  .steps {
    display: flex;
    align-items: center;
    flex: 1;
    &.step-prev {
      .step-box {
        .step-number {
          border-color: $dveb-info;
          color: $dveb-info;
        }
      }
    }
    &.step-active {
      .step-box {
        .step-number {
          border-color: $dveb-info;
          color: #fff;
          background-color: $dveb-info;
        }
      }
    }
    .step-box {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .step-number {
        min-width: 27px;
        width: 100%;
        display: flex;
        justify-content: center;
        border: 3px solid $dveb-divider;
        color: $dveb-divider;
        font-weight: 500;
        &.step-lg {
          font-size: 1.2rem;
        }
      }
      span {
        font-size: 0.7rem;
        font-weight: 300;
      }
    }
    .divider {
      display: flex;
      align-items: center;
      flex: 1;
      height: 1px;
      background-color: $dveb-divider;
      margin-bottom: 10px;
      &.active {
        background-color: $dveb-info;
      }
      &.none {
        display: none;
      }
      &.arrow {
        &::after {
          content: "";
          border: solid 5px transparent;
          border-left-color: #d7dee2;
          position: absolute;
          top: 10px;
          right: 0;
        }
      }
    }
  }
}
</style>
